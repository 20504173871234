import React, { useEffect, useState } from "react";
import { Card, Container } from 'reactstrap';
import Appartment from "../../assets/images/appartment.png";
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import { useNavigate } from 'react-router-dom';
import { getPropertyOwner } from "../../api/owner";
import { useProfile } from "../../components/Hooks/UserHooks";
import AddressComponent from "../../components/Common/Address";
import dayjs from "dayjs";
import { getS3BaseUrl } from "../../helpers/string_helper";
import { PROPERTY_TYPES } from "../../common/constant";
import NodataFound from "../../components/Common/NoDataFound";
import NoDataIcon from "../../assets/images/no-data.png";
import { selectGenderPreferenceIcon } from "../../common/amenities_icon";

export default function TenantDashboard() {
    const [proprtyOwner, setPropertyOwner] = useState();
    const { user } = useProfile()
    const s3BaseUrl = getS3BaseUrl()

    useEffect(() => {
        fetchPropertyOwner()
    }, [])

    const fetchPropertyOwner = async () => {
        try {
            const response = (await getPropertyOwner(user?.property_owner?.id)).data.data
            const result = response.property.map((item) => {
                let tenantArray = []
                let vacantRequestData = []
                if (item.user === null) {
                    let floorData = []
                    item?.floors?.forEach(floor => {
                        let flatData = []
                        if (!floor.user || floor.user?.id === user.id) {
                            if (floor?.flats?.length > 0 && item.type === PROPERTY_TYPES.APARTMENT) {
                                floor.flats.forEach((flat) => {
                                    if (flat.user.id === user.id && floor.user === null) {
                                        flat.rooms.forEach((room) => {
                                            room.beds.forEach((bed) => {
                                                if (bed.user && bed?.user?.tenant?.id) {
                                                    const isValidTenant = bed.vacant_requests?.length > 0 && bed.vacant_requests[0]?.tenant_id === bed?.user?.tenant?.id
                                                    if (isValidTenant ? dayjs(bed.vacant_requests[0]?.leaving_date) > dayjs() : true) {
                                                        const filterTenant = item.tenant?.filter((it) => it.id === bed?.user?.tenant?.id);
                                                        tenantArray.push(filterTenant[0])
                                                        if (isValidTenant && dayjs(bed.vacant_requests[0]?.leaving_date) > dayjs()) {
                                                            vacantRequestData.push({
                                                                bed_count: room?.bed_count,
                                                                room_type: room?.room_type,
                                                                user: bed?.user,
                                                                vacant_request: bed.vacant_requests[0]
                                                            })
                                                        }
                                                    }
                                                }
                                            })
                                        })
                                        flatData.push(flat)
                                    }
                                })
                            } else if (item.type === PROPERTY_TYPES.PERSONAL_HOME) {
                                floor.rooms.forEach((room) => {
                                    room.beds.forEach((bed) => {
                                        if (bed.user && bed?.user?.tenant?.id) {
                                            const isValidTenant = bed.vacant_requests?.length > 0 && bed.vacant_requests[0]?.tenant_id === bed?.user?.tenant?.id
                                            if (isValidTenant ? dayjs(bed.vacant_requests[0]?.leaving_date) > dayjs() : true) {
                                                const filterTenant = item.tenant?.filter((it) => it.id === bed?.user?.tenant?.id);
                                                tenantArray.push(filterTenant[0])

                                                if (isValidTenant && dayjs(bed.vacant_requests[0]?.leaving_date) > dayjs()) {
                                                    vacantRequestData.push({
                                                        bed_count: room?.bed_count,
                                                        room_type: room?.room_type,
                                                        user: bed?.user,
                                                        vacant_request: bed.vacant_requests[0]
                                                    })
                                                }
                                            }
                                        }
                                    })
                                })
                            }
                        }
                        if (!(flatData?.length === 0 && item.type === PROPERTY_TYPES.APARTMENT)) {
                            floorData.push({ ...floor, flats: flatData })
                        }
                    });

                    return {
                        ...item,
                        floors: floorData,
                        vacant_requests: vacantRequestData,
                        name: item.name,
                        address: <AddressComponent address={item.address} />,
                        totalTenants: tenantArray?.length,
                        pictures: item.pictures,
                        listedSince: dayjs(item.created_at).format('DD.MM.YYYY'),
                        tenant: tenantArray,
                        schedule_visits: item?.tenant_property_visits?.map((visit) => {
                            return {
                                ...visit,
                                care_taker_name: item.care_taker_name,
                                care_taker_contact_no: item.care_taker_contact_no,
                            }
                        })
                    }
                } else {
                    let floorData = []
                    item?.floors?.forEach(floor => {
                        let flatData = []

                        if (floor?.flats?.length > 0 && item.type === PROPERTY_TYPES.APARTMENT) {
                            floor.flats.forEach((flat) => {
                                flat.rooms.forEach((room) => {
                                    room.beds.forEach((bed) => {
                                        if (bed.user && bed?.user?.tenant?.id) {
                                            const isValidTenant = bed.vacant_requests?.length > 0 && bed.vacant_requests[0]?.tenant_id === bed?.user?.tenant?.id
                                            if (isValidTenant ? dayjs(bed.vacant_requests[0]?.leaving_date) > dayjs() : true) {
                                                const filterTenant = item.tenant?.filter((it) => it.id === bed?.user?.tenant?.id);
                                                tenantArray.push(filterTenant[0])
                                                if (isValidTenant && dayjs(bed.vacant_requests[0]?.leaving_date) > dayjs()) {
                                                    vacantRequestData.push({
                                                        bed_count: room?.bed_count,
                                                        room_type: room?.room_type,
                                                        user: bed?.user,
                                                        vacant_request: bed.vacant_requests[0]
                                                    })
                                                }
                                            }
                                        }
                                    })
                                })
                                flatData.push(flat)
                            })
                        } else if (item.type === PROPERTY_TYPES.PERSONAL_HOME) {
                            floor.rooms.forEach((room) => {
                                room.beds.forEach((bed) => {
                                    if (bed.user && bed?.user?.tenant?.id) {
                                        const isValidTenant = bed.vacant_requests?.length > 0 && bed.vacant_requests[0]?.tenant_id === bed?.user?.tenant?.id
                                        if (isValidTenant ? dayjs(bed.vacant_requests[0]?.leaving_date) > dayjs() : true) {
                                            const filterTenant = item.tenant?.filter((it) => it.id === bed?.user?.tenant?.id);
                                            tenantArray.push(filterTenant[0])
                                            if (isValidTenant && dayjs(bed.vacant_requests[0]?.leaving_date) > dayjs()) {
                                                vacantRequestData.push({
                                                    bed_count: room?.bed_count,
                                                    room_type: room?.room_type,
                                                    user: bed?.user,
                                                    vacant_request: bed.vacant_requests[0]
                                                })
                                            }
                                        }
                                    }
                                })
                            })
                        }

                        if (!(flatData?.length === 0 && item.type === PROPERTY_TYPES.APARTMENT)) {
                            floorData.push({ ...floor, flats: flatData })
                        }
                    });
                    return {
                        ...item,
                        name: item.name,
                        address: <AddressComponent address={item.address} />,
                        totalTenants: tenantArray?.length,
                        pictures: item.pictures,
                        listedSince: dayjs(item.created_at).format('DD.MM.YYYY'),
                        vacant_requests: vacantRequestData,
                        tenant: tenantArray,
                        schedule_visits: item?.tenant_property_visits?.map((visit) => {
                            return {
                                ...visit,
                                care_taker_name: item.care_taker_name,
                                care_taker_contact_no: item.care_taker_contact_no,
                            }
                        })
                    }
                }
            })
            setPropertyOwner(result)
        } catch (err) {
            console.log(err)
        }
    }

    const navigate = useNavigate();

    return (
        <div className="page-content">
            <Container fluid>
                <div className="owner_dashboard">
                    {(!proprtyOwner || proprtyOwner?.length === 0) && (
                        <div style={{ textAlign: 'center', height: '500px' }}>
                            <NodataFound text='No property available to show.' icon={NoDataIcon} />
                        </div>
                    )}
                    {proprtyOwner?.map((property, index) => (
                        <Card key={index} className="mb-4" onClick={() => {
                            navigate('/owner-dashboard/owner-property-details', {
                                state: {
                                    property: JSON.stringify(property)
                                }
                            })
                        }} style={{ cursor: 'pointer' }}>
                            <div className="appartment_details d-grid two_main_grid_cols" >
                                <div className="d-grid single_item" style={{
                                    display: 'inline-block'
                                }}>
                                    <div className="appartment_img mx-2">

                                        {property?.default_image?.name ? (
                                            <img src={`${s3BaseUrl}${property?.default_image?.location}`} alt={property?.default_image?.name} />
                                        ) : <img src={Appartment} alt={property.name} />}

                                    </div>
                                </div>
                                <div className="child_single_item">
                                    <h5>{property.name}</h5>
                                    <div className="d-flex">
                                        <FmdGoodOutlinedIcon />
                                        <p className="m-0">{property.address}</p>
                                    </div>
                                    <div className="d-flex mt-2">
                                        {selectGenderPreferenceIcon(property?.gender_preference)}
                                        <p className="m-0">Gender Preference <b>{property.gender_preference}</b></p>
                                    </div>
                                    <div className="d-flex mt-2">
                                        <GroupsOutlinedIcon />
                                        <p className="m-0">Total Tenant <b>{property.totalTenants}</b></p>
                                    </div>
                                    <div className="d-flex listed_text">
                                        <b className="m-0" style={{ color: 'red' }}>Listed Since {property.listedSince}</b>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    ))}
                </div>
            </Container>
        </div>
    )
}
