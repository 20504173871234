import React, { useState } from "react";
import { Card } from 'reactstrap';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import BloodtypeIcon from '@mui/icons-material/Bloodtype';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import ContactPhoneOutlinedIcon from '@mui/icons-material/ContactPhoneOutlined';
import RingVolumeOutlinedIcon from '@mui/icons-material/RingVolumeOutlined';
import NoDataIcon from "../../assets/images/no-data.png";
import { Modal } from "@mui/material";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Box from '@mui/material/Box';
import AmenitiesSlider from "../../components/Dashboard/amenitiesSlider";
import TenantProfileIcon from '../../assets/images/user-3-fill.png'
import { getS3BaseUrl } from "../../helpers/string_helper";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import NodataFound from "../../components/Common/NoDataFound";
import { BED_TYPES, DEFAULT_DATE_FORMAT } from "../../common/constant";
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import GavelOutlinedIcon from '@mui/icons-material/GavelOutlined';
import PercentOutlinedIcon from '@mui/icons-material/PercentOutlined';
import GppMaybeOutlinedIcon from '@mui/icons-material/GppMaybeOutlined';
import MapsHomeWorkOutlinedIcon from '@mui/icons-material/MapsHomeWorkOutlined';
import AcUnitOutlinedIcon from '@mui/icons-material/AcUnitOutlined';
import BedOutlinedIcon from '@mui/icons-material/BedOutlined';
import CurrencyRupeeOutlinedIcon from '@mui/icons-material/CurrencyRupeeOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import moment from "moment";

const documentStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    // height: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: '10px'
};

function TenantDetails({ tenant }) {
    const [documents, setDocuments] = useState();
    const [isDocumentModal, setIsDocumentModal] = useState(false);
    const handleClose = () => setIsDocumentModal(false)
    const s3BaseUrl = getS3BaseUrl()
    const fetchBedType = (bedCount) => {
        switch (bedCount) {
            case 1:
                return BED_TYPES.SINGLE;
            case 2:
                return BED_TYPES.DOUBLE;
            case 3:
                return BED_TYPES.TRIPLE;
            default:
                return ''
        }
    }

    return (
        <div className="tenant_details_pg tenant_dashboard">

            {tenant?.length === 0 && (
                <div style={{ textAlign: 'center', height: '500px' }}>
                    <NodataFound text='No tenant available to show.' icon={NoDataIcon} />
                </div>
            )}
            {tenant?.length > 0 && tenant?.map((item) => (
                <Card key={item?.id}>
                    <div className="appartment_details grid_appartment d-grid two_main_grid_cols">
                        <div className="d-grid two_grid_cols single_item">
                            <div className="appartment_img d-flex align-items-start">
                                {
                                    item?.user?.profile_photo ? (
                                        <img src={`${s3BaseUrl}${item?.user?.profile_photo}`} alt={item?.user?.name} />
                                    ) : <img src={TenantProfileIcon} alt={item?.user?.name} />
                                }
                            </div>
                        </div>
                        <div className="all_details">
                            <div className="tenant_person_detail">{item?.user?.name}</div>
                            <div className="bed_rate child_single_item child_single_item_responsive pe-0">
                                <div className="d-flex rounded_icon">
                                    <PersonOutlineIcon />
                                    <div>
                                        <p>Name</p>
                                        <h6>{item?.user?.name}</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="d-grid group_grid_cols single_item">
                                <div className="bed_rate child_single_item">
                                    <div className="d-flex rounded_icon">
                                        <AssignmentIndOutlinedIcon />
                                        <div>
                                            <p>Type</p>
                                            <h6>{item?.type}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="bed_rate child_single_item">
                                    <div className="d-flex rounded_icon">
                                        <BadgeOutlinedIcon />
                                        <div>
                                            <p>ID</p>
                                            <h6>{item?.user?.code}</h6>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="bed_rate child_single_item">
                                    <div className="d-flex rounded_icon">
                                        <PersonOutlineIcon />
                                        <div>
                                            <p>Guardian’s Name</p>
                                            <h6>{item?.guardian_name}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="bed_rate child_single_item">
                                    <div className="d-flex rounded_icon">
                                        <ContactPhoneOutlinedIcon />
                                        <div>
                                            <p>Guardian’s Contact Number</p>
                                            <h6>{item?.guardian_contact_no}</h6>
                                        </div>
                                    </div>
                                </div> 
                                <div className="bed_rate child_single_item">
                                    <div className="d-flex rounded_icon">
                                        <PersonOutlineIcon />
                                        <div>
                                            <p>Emergency Contact Name</p>
                                            <h6>{item?.local_guardian_name}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="bed_rate child_single_item">
                                    <div className="d-flex rounded_icon">
                                        <ContactPhoneOutlinedIcon />
                                        <div>
                                            <p>Emergency Contact Number</p>
                                            <h6>{item?.local_guardian_contact_no}</h6>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="bed_rate child_single_item">
                                    <div className="d-flex rounded_icon">
                                        <GavelOutlinedIcon />
                                        <div>
                                            <p>Agreement Status</p>
                                            <h6>{item?.agreement?.status}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="bed_rate child_single_item">
                                    <div className="d-flex rounded_icon">
                                        <GavelOutlinedIcon />
                                        <div>
                                            <p>Police Verification Status</p>
                                            <h6>{item?.police_verification?.status}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="bed_rate child_single_item">
                                    <div className="d-flex rounded_icon">
                                        <PercentOutlinedIcon />
                                        <div>
                                            <p>Payout Percentage</p>
                                            <h6>{item?.custom_payout_percentage}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="bed_rate child_single_item">
                                    <div className="d-flex rounded_icon">
                                        <GppMaybeOutlinedIcon />
                                        <div>
                                            <p>Reason for Payout Percentage</p>
                                            <h6>{item?.reason_payout_percentage}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="bed_rate child_single_item">
                                    <div className="d-flex rounded_icon">
                                        <BloodtypeIcon />
                                        <div>
                                            <p>Blood Group</p>
                                            <h6>{item?.user?.blood_group}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="bed_rate child_single_item">
                                    <div className="d-flex rounded_icon">
                                        <MapsHomeWorkOutlinedIcon />
                                        <div>
                                            <p>Floor Number</p>
                                            <h6>{item?.user?.bed?.room?.flat?.floor?.name}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="bed_rate child_single_item">
                                    <div className="d-flex rounded_icon">
                                        <MapsHomeWorkOutlinedIcon />
                                        <div>
                                            <p>Room Number</p>
                                            <h6>{item?.user?.bed?.room?.label}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="bed_rate child_single_item">
                                    <div className="d-flex rounded_icon">
                                        <AcUnitOutlinedIcon />
                                        <div>
                                            <p>Room Type</p>
                                            <h6>{item?.user?.bed?.room?.room_type?.name}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="bed_rate child_single_item">
                                    <div className="d-flex rounded_icon">
                                        <BedOutlinedIcon />
                                        <div>
                                            <p>Bed Type</p>
                                            <h6>{fetchBedType(item?.user?.bed?.room?.bed_count)}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="bed_rate child_single_item">
                                    <div className="d-flex rounded_icon">
                                        <CurrencyRupeeOutlinedIcon />
                                        <div>
                                            <p>Bed Rate</p>
                                            <h6>{item?.user?.bed?.price}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="bed_rate child_single_item">
                                    <div className="d-flex rounded_icon">
                                        <AccountBalanceWalletOutlinedIcon />
                                        <div>
                                            <p>Deposit Amount</p>
                                            <h6>{item?.refund_deposits[0]?.deposit_amount}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="bed_rate child_single_item">
                                    <div className="d-flex rounded_icon">
                                        <AccountBalanceWalletOutlinedIcon />
                                        <div>
                                            <p>Date of Joining</p>
                                            <h6>{moment(item?.joining_date).format(DEFAULT_DATE_FORMAT)}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="bed_rate child_single_item">
                                    <div className="d-flex rounded_icon id_proof">
                                        <AttachFileOutlinedIcon />
                                        <div className="d-flex" onClick={() => {
                                            setIsDocumentModal(true)
                                            setDocuments(item?.other_documents)
                                        }}>
                                            <p className="me-2">Attachments</p>
                                            <VisibilityOutlinedIcon style={{ cursor: "pointer", height: "24px", width: "18px" }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            ))}

            {isDocumentModal && (
                <Modal
                    open={isDocumentModal}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"

                >
                    <Box sx={documentStyle} className="tenantRemove resp_tenant_modal">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="crossIcon cursor-pointer position-absolute">
                                <CloseOutlinedIcon onClick={handleClose} />
                            </div>
                        </div>
                        <div className="amenityImagesSlider">
                            <AmenitiesSlider files={documents} />
                        </div>
                    </Box>
                </Modal>
            )}

        </div>
    )
}

export default TenantDetails;