import React, { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TenantsDetail from './tenantDetails';
import BedMatrixDetails from './bedMatrix';
import RentHistory from './rentHistory';
import PayoutHistory from './payoutHistory';
import { useParams, useLocation } from 'react-router-dom';
import VacantRequest from './vacantRequest';
import ScheduleVisit from './scheduleVisit';
import PropertyReviews from '../../components/Common/PropertyReviews';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  const { detailValue } = props;
  //const { propertyType: detailValue} = useParams();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          <Typography>{React.cloneElement(children, { detailValue })}</Typography>
        </Box>
      )}
    </div>
  );
}


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
export default function ActivePropertiesDetails() {
  const [value, setValue] = React.useState(0);
  const { propertyType: detailValue } = useParams();
  const location = useLocation()
  const [property, setProperty] = useState()

  useEffect(() => {
    if (location?.state?.property) {
      setProperty(JSON.parse(location?.state?.property))
    }
  }, [location?.state?.property])

  useEffect(() => {
    const selectedMenu = parseInt(sessionStorage.getItem('selectedTab'));
    if (selectedMenu) {
      setValue(selectedMenu)
      sessionStorage.clear();
    }
  }, [])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="page-content" style={{
      padding: 'calc(70px + 1.25rem) calc(1.25rem / 2) 0px calc(1.25rem / 2)'
    }}>
      <div className="container-fluid">
        {/* Render Breadcrumb */}
        {/* <div className="d-flex">
                    <ArrowBackOutlinedIcon style={{marginRight: '10px', cursor: 'pointer'}} onClick={handleBack} /> 
                </div> */}
        {/* Tabs starts here */}
        <div className='active-property-main-detail'>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 0, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='aPTabs' variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile>
                <Tab label="Tenants Details" {...a11yProps(0)} />
                <Tab label="Bed Matrix" {...a11yProps(1)} />
                <Tab label="Payout Histories" {...a11yProps(2)} />
                <Tab label="Rent Slips" {...a11yProps(3)} />
                <Tab label="Vacant Request" {...a11yProps(4)} />
                <Tab label="Scheduled Visit" {...a11yProps(5)} />
                <Tab label="Reviews" {...a11yProps(5)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <TenantsDetail tenant={property?.tenant} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1} propertyDetailValue={detailValue}>
              <BedMatrixDetails propertyType={detailValue} property={property} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <PayoutHistory propertyId={property?.id} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <RentHistory p_state={location?.state} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={4}>
              <VacantRequest VacantRequestData={property?.vacant_requests} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={5}>
              <ScheduleVisit ScheduleVisitData={property?.schedule_visits} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={6}>
              <PropertyReviews propertyId={property?.id} reviewCompHeight={69} />
            </CustomTabPanel>
          </Box>
        </div>
      </div>
    </div>
  )
}