import React, { useState } from "react";
import HotelOutlinedIcon from '@mui/icons-material/HotelOutlined';
import Photo from "../../assets/images/photo-icon.svg";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import AmenitiesSlider from "./amenitiesSlider";
import DomainOutlinedIcon from '@mui/icons-material/DomainOutlined';
import { BED_TYPES } from "../../common/constant";
import Tooltip from "@mui/material/Tooltip";
import { selectAmenitiesIcon, selectProfessionIcon } from "../../common/amenities_icon";
import dayjs from "dayjs";

const amenitiesStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: '10px',
    '@media screen and (max-width: 768px)': {
        width: 'calc(100% - 20px)',
        maxWidth: 500,
    }
};
function FloorBedMatrix(props) {
    const [selectedAmenities, setSelectedAmenities] = useState([]);
    const [selectedDocuments, setSelectedDocuments] = useState([]);
    const [amenityOpen, setAmenityOpen] = useState(false);
    const amenityHandleOpen = () => setAmenityOpen(true);
    const amenityHandleClose = () => setAmenityOpen(false);

    const fetchBedType = (bedCount) => {
        switch (bedCount) {
            case 1:
                return BED_TYPES.SINGLE;
            case 2:
                return BED_TYPES.DOUBLE;
            case 3:
                return BED_TYPES.TRIPLE;
            default:
                return ''
        }
    }

    return (
        <div className="floorBedMatrix">
            <h5>{props?.flat ? (
                <><span className="flat_color"><DomainOutlinedIcon /> {props?.flat}</span><span className="floor_color"> (Floor {props.floor === '0th' ? 'Ground' : props.floor})</span></>)
                : (<><span className="flat_color"><DomainOutlinedIcon /> Floor {props.floor === '0th' ? 'Ground' : props.floor}</span></>)
            }
            </h5>
            <div className="mainCardBlock d-flex">
                {props.rooms?.length > 0 && props?.rooms?.map((item, index) => (
                    <div className="card" key={index}>
                        <div className="fbmRoom d-flex align-items-start w-100">
                            <div className="room_ico"><HotelOutlinedIcon className="me-2" /></div>
                            <div className="fbmRoomDetail">
                                <h5>{item.name}</h5>
                                <p>{item?.room_type?.name}, {fetchBedType(item.bed_count)}</p>
                            </div>
                            <Tooltip title="amenities & picture">
                                <img src={Photo} alt={Photo} onClick={() => {
                                    setSelectedAmenities(item.amenities)
                                    setSelectedDocuments(item.documents)
                                    amenityHandleOpen()
                                }} className="cursor-pointer mx-2" />
                            </Tooltip>
                        </div>
                        {item.beds.map((bed, index) => (
                            <div className="fbmRoombed d-flex align-items-start justify-content-between" key={index}>
                                <div className="d-flex">
                                    {selectProfessionIcon(bed?.user?.tenant?.type)}
                                    <div className="fbmRoomBedDetail">
                                        <h5>{bed.name}</h5>
                                        <p>Rate: {bed.price}</p>
                                        {(bed.vacant_requests?.length > 0 && bed.vacant_requests[0]?.tenant_id === bed?.user?.tenant?.id) ? (
                                            dayjs(bed.vacant_requests[0]?.leaving_date) > dayjs() && (
                                                <>
                                                    <p>{bed?.user?.name}</p>
                                                    {bed.vacant_requests?.length > 0 && (
                                                        <p className="text-danger" style={{ fontSize: '11px', marginBottom: '0px', marginTop: '0px' }}> Available from {dayjs(bed.vacant_requests[0]?.leaving_date).add(1, 'day').format('DD-MM-YYYY')}</p>
                                                    )}
                                                </>
                                            )
                                        ) : (
                                            <>
                                                <p>{bed?.user?.name}</p>
                                                {bed.user?.tenant?.joining_date && (
                                                    <p className="text-danger" style={{ fontSize: '11px', marginBottom: '0px', marginTop: '0px' }}> Booked from {dayjs(bed.user?.tenant?.joining_date).format('DD-MM-YYYY')}</p>

                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>

            {/* Amenity Modal open */}
            {amenityOpen && (
                <Modal
                    open={amenityOpen}
                    onClose={amenityHandleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"

                >
                    <Box sx={amenitiesStyle} className="tenantRemove">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="crossIcon cursor-pointer position-absolute">
                                <CloseOutlinedIcon onClick={amenityHandleClose} />
                            </div>
                        </div>
                        <div className="amenityImagesSlider mb-3">
                            <AmenitiesSlider files={selectedDocuments} />
                        </div>
                        <div className="detail_block amenityCustomBox py-0">
                            <h6 className="block_heading">Amenities</h6>
                            <ul className="custom_amenties_options d-flex flex-wrap ps-0 mb-0" style={{ listStyle: 'none' }}>
                                {(selectedAmenities && selectedAmenities?.length > 0) ? selectedAmenities.map((item, index) => (
                                    <li className="position-relative d-flex" key={index + 1} style={{
                                        color: '#4c4343'
                                    }}>
                                        {selectAmenitiesIcon(item?.toLowerCase())} <div className="amenity_item">{item}</div>
                                    </li>
                                )) : 'No amenities are available for this room'}
                            </ul>
                        </div>
                    </Box>
                </Modal>
            )}
        </div>
    )
}
export default FloorBedMatrix;