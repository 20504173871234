import React, { useEffect, useMemo, useRef, useState } from "react";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import EmojiObjectsOutlinedIcon from "@mui/icons-material/EmojiObjectsOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import CreditScoreOutlinedIcon from "@mui/icons-material/CreditScoreOutlined";
import SyncAltOutlinedIcon from "@mui/icons-material/SyncAltOutlined";
import Button from "@mui/material/Button";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { toast } from "react-toastify";
import { getRentSlips } from "../../api/owner";
import ViewNote from "../../components/Common/ViewNote";
import NodataFound from "../../components/Common/NoDataFound";
import { monthArray } from "../../helpers/constants";
import SimpleCarousel from "../../components/Common/SimpleCarousel";
import NoDataIcon from "../../assets/images/no-data.png";

export default function RentHistory({ p_state }) {
  const [meta, setMeta] = React.useState({});
  const [notes, setNote] = useState("");
  const [noteView, setNoteView] = useState(false);
  const [imageToggle, setImageToggle] = useState(false);
  const [slips, setSlips] = useState([]);
  const [attachments,setAttachments] = useState([])
  const p_details = useRef(null);
  p_details.current = useMemo(() => {
    const stringValue = JSON.parse(p_state?.property?.replace("''", ""));
    return stringValue;
  }, []);
  const month_num = useRef(null);
  const [params, setParams] = useState({
    limit: 10,
    page: 1,
    property_id: p_details.current?.id,
    filter_field: "month",
    filter_input_month: String(new Date().getMonth() + 1),
    filter_input_year: String(new Date().getFullYear()),
  });

  const fetchAllSlips = async (params) => {
    try {
      const response = await getRentSlips(params);
      if (response?.status === 200) {
        if (params.filter_input_month === month_num.current) {
          setSlips((old) => [...old, ...(response?.data?.data?.data?.data||[])]);
          setAttachments(response?.data?.data?.data?.attachments||[])
          setMeta(response?.data?.data?.meta);
        } else {
          setSlips([...(response?.data?.data?.data?.data||[])]);
          setAttachments(response?.data?.data?.data?.attachments||[])
          setMeta(response?.data?.data?.meta);
          month_num.current = params.filter_input_month;
        }
      }
    } catch (e) {
      toast.error(e.message);
    }
  };
  
  useEffect(() => {
    fetchAllSlips(params);
  }, []);

  const handleClickOpen = () => {};
  const handleOpenDocPreview = () => {
    setImageToggle(true)
  };
  const toggleNote = () => {
    setNoteView(!noteView);
  };

  const handleChange = (event) => {
    if (!month_num.current) {
      month_num.current = String(Number(event?.target?.value) - 1);
    }
    setParams((old) => ({
      ...old,
      ...(month_num.current !== String(Number(event?.target?.value) - 1) && {
        page: 1,
      }),
      filter_input_month: String(Number(event?.target?.value) - 1),
    }));
    const paramsData = {
      ...params,
      ...(month_num.current !== String(Number(event?.target?.value) - 1) && {
        page: 1,
      }),
      filter_input_month: String(Number(event?.target?.value) - 1),
    };
    fetchAllSlips(paramsData);
  };
  const getStatusColor = (status) => {
    return status === "Paid" ? "#04A314" : "Red";
  };
  const handleScroll = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.currentTarget;
    
    if (scrollHeight - (scrollTop + clientHeight) < 2) {
      if (meta?.current_page < meta?.last_page) {
        setParams((old) => ({
          ...old,
          page: old.page + 1,
        }));
        const paramsData = {
          ...params,
          page: params.page + 1,
        };
        fetchAllSlips(paramsData);
      }
    }
  };

  return (
    <div className="rent_history_tab">
      <div className="complain_ticket">
        <div className="inner_page_header d-flex justify-content-between">
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={Number(params.filter_input_month) + 1}
            label=""
            onChange={handleChange}
            className="mb-3"
          >
            {Object.entries(monthArray).map(([a, b]) => (
              <MenuItem key={b} value={+a + 1}>
                {b}
              </MenuItem>
            ))}
          </Select>
          { attachments.length>0 && <Button
            className="yellow_gradient_btn pay_full_amt view_file_btn"
            onClick={handleOpenDocPreview}
            style={{ cursor: "pointer" }}
          >
            <DescriptionOutlinedIcon /> &nbsp; View Files{" "}
          </Button>}
        </div>
        <div
          style={{
            maxHeight: "67vh",
            overflowY: "scroll",
            overflowX: "unset",
          }}
          onScroll={handleScroll}
        >
          <div className="overall_tickets list-inline">
            {slips?.length ? (
              slips.map((data, index) => (
                <div className="single_ticket" key={index}>
                  <div
                    className="single_ticket_header d-flex justify-content-between align-items-center"
                    onClick={handleClickOpen}
                    key={data?.id}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="ticket_id">
                      <b>{data?.tenant?.user?.name}</b>
                    </div>
                    <div className="rs_header_right d-flex">
                      <div
                        className="ticket_id me-3"
                        style={{
                          borderRight: "1px solid #ddd",
                          paddingRight: "15px",
                        }}
                      >
                        <b>Total: ₹ {Number(data.total_amount)}</b>
                      </div>
                      <div
                        className="rent_status"
                        onClick={() => {
                          setNote(data?.note?.description || "");
                          toggleNote();
                        }}
                      >
                        <VisibilityOutlinedIcon /> View Notes
                      </div>
                    </div>
                  </div>
                  <div
                    className="single_ticket_body d-grid rent_six_grid_cols"
                    key={index}
                  >
                    <div className="create_date breaked_value">
                      <div className="responsive_word_break">
                        <HomeOutlinedIcon /> Rent
                      </div>{" "}
                      <b>₹ {data.rent}</b>
                    </div>
                    <div className="resolved_by breaked_value">
                      <div className="responsive_word_break">
                        <EmojiObjectsOutlinedIcon />
                        Electric Amount
                      </div>
                      <span>
                        ₹ <b>{Number(data?.electricity_amount) || 0}</b>
                      </span>
                    </div>
                    <div className="resolved_by breaked_value">
                      <div className="responsive_word_break">
                        <ArticleOutlinedIcon />
                        Other Charges
                      </div>
                      <span>
                        ₹ <b>{Number(data?.other_amount)}</b>
                      </span>
                    </div>
                    <div className="resolved_by breaked_value">
                      <div className="responsive_word_break">
                        <CreditScoreOutlinedIcon />
                        Payment Status
                      </div>{" "}
                      <b style={{ color: getStatusColor(data.status) }}>
                        {data.status}
                      </b>
                    </div>
                    <div className="resolved_by breaked_value">
                      <div className="responsive_word_break">
                        <CalendarTodayOutlinedIcon />
                        Payment Date
                      </div>{" "}
                      <b>{data.paymentDate || "N/A"}</b>
                    </div>
                    <div className="resolved_date breaked_value">
                      <div className="responsive_word_break">
                        <SyncAltOutlinedIcon /> Transaction Id
                      </div>{" "}
                      <b>{data.transactionId || "N/A"}</b>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div style={{ textAlign: "center" }}>
                  <NodataFound text="No slip to show" icon={NoDataIcon} />
              </div>
            )}
          </div>
        </div>
        {imageToggle && (
          <SimpleCarousel
            open={imageToggle}
            setOpen={setImageToggle}
            onClose={() => setImageToggle(false)}
            images={attachments}
          />
        )}
        {noteView && (
          <ViewNote
            isAddNoteOpen={noteView}
            isAddNotetoggle={toggleNote}
            value={notes}
            add_title={"Note"}
          />
        )}
      </div>
    </div>
  );
}
